import * as React from 'react';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import Box from '@mui/material/Box';
import Button from './components/Button';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import ProductHeroLayout from './views/ProductHeroLayout';
import Stack from '@mui/material/Stack';
import Typography from './components/Typography';
import backgroundImage from '../../assets/OneCreatorImage.png';
import en from '../../data/en.json';
import withRoot from '../../withRoot';

function About() {
  const focusAreas = [
    { 
      "area": en.marketing.aboutSection.AcademicExcellence,
      "tag": "#academic-excellence",
      "title": en.marketing.aboutSection.AcademicExcellenceTitle,
      "description": en.marketing.aboutSection.AcademicExcellenceDescription
    },
    {
      "area": en.marketing.aboutSection.CareerAdvancement,
      "tag": "#career-advancement",
      "title": en.marketing.aboutSection.CareerAdvancementTitle,
      "description": en.marketing.aboutSection.CareerAdvancementDescription
    },
    {
      "area": en.marketing.aboutSection.AcquiringCredentials,
      "tag": "#acquiring-credentials",
      "title": en.marketing.aboutSection.AcquiringCredentialsTitle,
      "description": en.marketing.aboutSection.AcquiringCredentialsDescription
    },
    {
      "area": en.marketing.aboutSection.PersonalFullfillment,
      "tag": "#personal-fulfillment",
      "title": en.marketing.aboutSection.PersonalFullfillmentTitle,
      "description": en.marketing.aboutSection.PersonalFullfillmentDescription
    },
    {
      "area": en.marketing.aboutSection.NewCreativeMethods,
      "tag": "#new-creative-methods",
      "title": en.marketing.aboutSection.NewCreativeMethodsTitle,
      "description": en.marketing.aboutSection.NewCreativeMethodsDescription
    }
  ]
  return (
    <React.Fragment>
      <ProductHeroLayout
        alignLeft
        sxBackground={{
          backgroundImage: `url(${backgroundImage})`,
          backgroundColor: '#7fc7d9', // Average color of the background image.
          backgroundPosition: 'center'
        }}
      >
        {/* Increase the network loading priority of the background image. */}
        <Typography color="inherit" align="left" variant="h1" marked="left">
          {en.marketing.aboutSection.title}
        </Typography>
        <Typography
          color="inherit"
          align="left"
          variant="h4"
          sx={{ mb: 4, mt: { xs: 4, sm: 10 } }}
        >
          {en.marketing.aboutSection.subtitle}
        </Typography>
      </ProductHeroLayout>

      {/* descriptions */}
      <Grid container spacing={2} sx={{ m: 2 }}>
        <Grid item xs={12} sm={3} />
        <Grid item xs={12} sm={6}>
          <Typography variant="h6">
            {en.marketing.aboutSection.descriptionOne}
          </Typography>
        </Grid>
      </Grid>

      <Grid container spacing={2} sx={{ m: 2 }}>
        <Grid item xs={12} sm={3} />
        <Grid item xs={12} sm={6}>
          <Typography variant="h6">
            {en.marketing.aboutSection.descriptionTwo}
          </Typography>

          <Typography variant="h4" sx={{mt: 2}}>
            {en.marketing.aboutSection.exploreOfferings}
          </Typography>
        </Grid>
      </Grid>

      {/* focus areas */}
      <Grid container spacing={2} sx={{ m: 2 }}>
        <Grid item xs={12} sm={3} />
        <Grid item xs={12} sm={6}>
          <Stack direction="row" spacing={2} sx={{mt: 2, mb: 2}}>
            {focusAreas.map((focusArea, index) => (
              <Link href={focusArea.tag} key={index}>{focusArea.area}</Link>
            ))}
          </Stack>
        </Grid>
      </Grid>

      <Grid container spacing={2} sx={{ m: 2 }}>
        <Grid item xs={12} sm={3} />
        <Grid item xs={12} sm={6}>
            {focusAreas.map((focusArea, index) => (
              <Box key={index}>
                <Typography variant="h4" sx={{mt: 2}} id={focusArea.tag}>
                  {focusArea.title}
                </Typography>
                <Typography variant="body2" sx={{mt: 2}}>
                  {focusArea.description}
                </Typography>
              </Box>
            ))}
        </Grid>
      </Grid>      
    </React.Fragment>
  );
}

export default withRoot(About);
