import * as React from 'react';
import { Theme } from '@mui/material/styles';
import { SxProps } from '@mui/system';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import IconButton from '@mui/material/IconButton';
import Divider from '@mui/material/Divider';
import Button from '../components/Button';
import Typography from '../components/Typography';
import inkFlowImage from '../../../assets/ink-flow.gif';
import SchoolIcon from '@mui/icons-material/School';
import LocalLibraryIcon from '@mui/icons-material/LocalLibrary';
import Diversity3Icon from '@mui/icons-material/Diversity3';
import RocketIcon from '@mui/icons-material/Rocket';
import WbTwilightIcon from '@mui/icons-material/WbTwilight';
import CurvyLines from "../../../assets/productCurvyLines.png";
import visualArt from "../../../assets/visualArt.png";
import en from "../../../data/en.json"

const item: SxProps<Theme> = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  px: 5,
};

const number = {
  fontSize: 24,
  fontFamily: 'default',
  color: 'secondary.main',
  fontWeight: 'medium',
};

const values = [
  { description: "Achieve Academic Excellence", image: <SchoolIcon style={{fontSize: 60}}/> },
  { description: "Gain Valuable Credentials", image: <LocalLibraryIcon style={{fontSize: 60}} /> },
  { description: "Kick-start Your Career in Art or Literature", image: <RocketIcon style={{fontSize: 60}}/> },
  { description: "Network and Share Your Work", image: <Diversity3Icon style={{fontSize: 60}}/> },
  { description: "Expand Your Creative Methods", image: <WbTwilightIcon style={{fontSize: 60}}/> },
]

function OneCreator() {
  return (
    <Box
      component="section"
      sx={{ display: 'flex', flexDirection: "column", bgcolor: 'common.white', overflow: 'hidden' }}
    >
      {
        // we have five values within the grid
        // we want the first three values in one rwo
        // and the last two values in the second row and centered
      }
      <Container sx={{ mt: 15, position: 'relative', display: 'flex', justifyContent: "center"}}>
        <Grid container spacing={2}>
          {[0, 1, 2].map((index) => (
            <Grid item xs={12} md={4} key={index}>
              <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center">
                <Typography variant="h5" align="center" sx={{mb: 5}}>
                  {values[index].description}
                </Typography>
                <Divider />
                {values[index].image}
              </Box>
            </Grid>
          ))}
        </Grid>

      </Container>
      <Container sx={{ mt: 15, mb: 15, position: 'relative', display: 'flex', justifyContent: "center"}}>
        <Grid container spacing={2}>
          {[3, 4].map((index) => (
            <Grid item xs={12} md={6} key={index}>
              <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center">
                <Typography variant="h5" align="center" sx={{mb: 5}}>
                  {values[index].description}
                </Typography>
                <Divider />
                {values[index].image}
              </Box>
            </Grid>
          ))}
        </Grid>
      </Container>
    </Box>
  );
}

export default OneCreator;
