import * as React from 'react';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Typography from '../components/Typography';

import ProductBuoy from '../../../assets/productBuoy.svg';
import en from '../../../data/en.json';

function ProductSmokingHero() {
  return (
    <Container
      component="section"
      sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', my: 9 }}
    >
      <Button
        sx={{
          border: '4px solid currentColor',
          borderRadius: 0,
          height: 'auto',
          py: 2,
          px: 5,
        }}
      >
        <Typography variant="h4" component="span">
          {en.marketing.readyToStart}
        </Typography>
      </Button>
      <Link variant="h6" underline="none" component="button"></Link>
      <Button sx={{ m: 5 }} size="large" variant="contained" component="a" href="/dashboard">
        {en.common.signUp}
      </Button>
      <Box
        component="img"
        src={ProductBuoy}
        alt="buoy"
        sx={{ width: 60 }}
      />
    </Container>
  );
}

export default ProductSmokingHero;
