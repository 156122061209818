import * as React from 'react';
import Button from '../components/Button';
import Typography from '../components/Typography';
import ProductHeroLayout from './ProductHeroLayout';
import backgroundImage from '../../../assets/ink-flow.gif';
import en from '../../../data/en.json';

export default function ProductHero() {
  return (
    <ProductHeroLayout
      alignLeft
      sxBackground={{
        backgroundImage: `url(${backgroundImage})`,
        backgroundColor: '#7fc7d9', // Average color of the background image.
        backgroundPosition: 'center'
      }}
    >
      {/* Increase the network loading priority of the background image. */}
      <Typography color="inherit" align="left" variant="h1" marked="left">
        {en.marketing.welcome}
      </Typography>
      <Typography
        color="inherit"
        align="left"
        variant="h4"
        sx={{ mb: 4, mt: { xs: 4, sm: 10 } }}
      >
        {en.marketing.subtitle}
      </Typography>
      <Button size="large" variant="contained" component="a" href="/dashboard">
        {en.common.signUp}
      </Button>
    </ProductHeroLayout>
  );
}
