import * as React from 'react';
import AppAppBar from './views/AppAppBar';
import AppFooter from './views/AppFooter';
import SecondProductHero from './views/SecondProductHero';
import OneCreator from './views/OneCreator';
import ProductHero from './views/ProductHero';
import ProductSmokingHero from './views/ProductSmokingHero';
import ProductValues from './views/ProductValues';
import withRoot from '../../withRoot';

function Main() {
  return (
    <React.Fragment>
      <AppAppBar />
      <ProductHero />
      <ProductValues />
      <SecondProductHero />
      <OneCreator />
      <ProductSmokingHero />
      <AppFooter />
    </React.Fragment>
  );
}

export default withRoot(Main);
