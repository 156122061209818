import * as React from 'react';
import { Theme } from '@mui/material/styles';
import { SxProps } from '@mui/system';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import Typography from '../components/Typography';

import CurvyLines from "../../../assets/productCurvyLines.png";
import ProductValues2 from "../../../assets/productValues2.svg"

import en from "../../../data/en.json";

const item: SxProps<Theme> = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  px: 5,
};

function ProductValues() {
  const [expanded, setExpanded] = React.useState<string>("");
  return (
    <Box
      component="section"
      sx={{ display: 'flex', overflow: 'hidden', bgcolor: 'common.white' }}
    >
      <Container sx={{ mt: 15, mb: 15, display: 'flex', position: 'relative' }}>
        <Box
          component="img"
          src={CurvyLines}
          alt="curvy lines"
          sx={{ pointerEvents: 'none', position: 'absolute', top: -180 }}
        />
        <Grid container spacing={2} onMouseLeave={() => setExpanded("")}>
          {en.marketing.productValues.map((item, index) => (
            <Grid item xs={12} md={6} key={index}>
              <Box>
                <Typography variant="h6" sx={{ my: 5 }}>
                  {item.title}
                </Typography>
                <Accordion expanded={expanded == item.title} onMouseOver={() => setExpanded(item.title) } elevation={0} sx={{'&:before':{height:'0px'}}}>
                  <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography variant="h5" sx={{ my: 5 }}>
                      {item.subtitle}
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography variant="body1" style={{whiteSpace: 'pre-line'}}>
                      {item.description}
                    </Typography>
                  </AccordionDetails>
                </Accordion>
              </Box>
            </Grid>
          ))}
        </Grid>     
      </Container>
    </Box>
  );
}

export default ProductValues;
